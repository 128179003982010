textarea {
  height: 10em;
  width: 20em;
  float: left;
}

.row {
  display: block;
  clear: both;
}

.icon {
  margin: 0 0.5em;
}

.MuiTable-root {
  min-width: auto !important;
}